exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-fibre-js": () => import("./../../../src/pages/fibre.js" /* webpackChunkName: "component---src-pages-fibre-js" */),
  "component---src-pages-gallery-game-of-life-js": () => import("./../../../src/pages/gallery/game-of-life.js" /* webpackChunkName: "component---src-pages-gallery-game-of-life-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-jsx-content-file-path-blog-welcome-mdx": () => import("./../../../src/templates/blogPost.jsx?__contentFilePath=/opt/build/repo/blog/welcome.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-blog-welcome-mdx" */)
}

